import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Todo',
    component: () => import(/* webpackChunkName: "todo" */ '../views/TodoView.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/ReportsView.vue')
  },
  {
    path: '/clinics',
    name: 'Clinics',
    component: () => import(/* webpackChunkName: "clinics" */ '../views/ClinicsView.vue')
  },
  {
    path: '/parts',
    name: 'Partss',
    component: () => import(/* webpackChunkName: "parts" */ '../views/PartsView.vue')
  },
  {
    path: '/personal-stock',
    name: 'personal-stock',
    component: () => import(/* webpackChunkName: "parts" */ '../views/PersonalStockView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = !!store.state.user;

  if (to.name !== "Login" && !loggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
});


export default router
