import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { format } from 'date-fns'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    userId:0,
    user1: null,
    search: null,
    reports: [
      '13/05/2023, 14:24',
      '12/05/2023, 16:40',
      '12/05/2023, 10:11',
      '10/05/2023, 21:35',
    ],
    tasks: [
      {
        id: 1,
        title: 'Wake up',
        done: false,
        dueDate: '2020-10-16'
      },
      {
        id: 2,
        title: 'Get bananas',
        done: false,
        dueDate: '2020-10-17'
      },
      {
        id: 3,
        title: 'Eat bananas',
        done: false,
        dueDate: null
      }
    ],
    snackbar: {
      show: false,
      text: ''
    }
  },
  mutations: {
    setUserData(state, userData) {
      state.user = userData;

      sessionStorage.setItem("user", JSON.stringify(userData));

      if (userData.rememberMe) {
        localStorage.setItem("user", JSON.stringify(userData));
      }

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userData.accessToken}`;
    },
    clearUserData() {
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
      location.reload();
    },


    addReport(state) {
      let now = new Date();
      state.reports.unshift(format(now, 'dd/MM/yyyy, hh:mm'));
    },

    setUser(state, payload) {
      state.user = payload

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${state.user.accessToken}`;
    },

    setUserId(state, id) {
      state.userId = id
    },

    clearUser1(state) {
      state.user1 = null;
      location.reload();
    },

    setSearch(state, value) {
      state.search = value
    },

    addTask(state, newTaskTitle) {
      let newTask = {
        id: Date.now(),
        title: newTaskTitle,
        done: false,
        dueDate: null
      }
      state.tasks.push(newTask)
    },
    doneTask(state, id) {
      let task = state.tasks.filter(task => task.id === id)[0]
      task.done = !task.done
    },
    deleteTask(state, id) {
      state.tasks = state.tasks.filter(task => task.id !== id)
    },
    updateTaskTitle(state, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      task.title = payload.title
    },
    updateTaskDueDate(state, payload) {
      let task = state.tasks.filter(task => task.id === payload.id)[0]
      task.dueDate = payload.dueDate
    },
    showSnackbar(state, text) {
      let timeout = 0
      if (state.snackbar.show) {
        state.snackbar.show = false
        timeout = 300
      }
      setTimeout(() => {
        state.snackbar.show = true
        state.snackbar.text = text
      }, timeout)
    },
    hideSnackbar(state) {
      state.snackbar.show = false
    }
  },
  actions: {
    login({ commit }, credentials) {
      return axios.post("auth/login", credentials).then(({ data }) => {
        commit("setUserData", data);
      });
    },
    logout({ commit }) {
      commit("clearUserData");
    },

    setUserId({ commit }, id) {
      commit('setUserId', id)
    },

    addTask({ commit }, newTaskTitle) {
      commit('addTask', newTaskTitle)
      commit('showSnackbar', 'Task added!')
    },
    deleteTask({ commit }, id) {
      commit('deleteTask', id)
      commit('showSnackbar', 'Task deleted!')
    },
    updateTaskTitle({ commit }, payload) {
      commit('updateTaskTitle', payload)
      commit('showSnackbar', 'Task updated!')
    },
    updateTaskDueDate({ commit }, payload) {
      commit('updateTaskDueDate', payload)
      commit('showSnackbar', 'Due Date Updated!')
    }
  },
  getters: {
    user: (state) => {
      let uFromStorage =
        sessionStorage.getItem("user") || localStorage.getItem("user");
      let x = null;
      if (uFromStorage) {
        x = JSON.parse(uFromStorage);
      }
      return state.user || x;
    },

    userId: (state) => {
      return state.userId
    },

    tasksFiltered(state) {
      if (!state.search) {
        return state.tasks
      }
      return state.tasks.filter(task =>
        task.title.toLowerCase().includes(state.search.toLowerCase())
      )
    }
  }
})
