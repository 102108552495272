<template>
  <v-snackbar color="success" v-model="$store.state.snackbar.show">

    <v-icon>mdi-check</v-icon>

    {{ $store.state.snackbar.text }}

    <!-- <template v-slot:action="{ attrs }">
      <v-btn @click="$store.commit('hideSnackbar')" text v-bind="attrs">
        סגירה
      </v-btn>
    </template> -->
  </v-snackbar>
</template>

<script>
export default {

}
</script>

<style></style>